<template>
  <div class="booker-awards-container" v-if="category">
    <CategoryTitle :category="category" />

    <v-btn
      class="mt-5"
      outlined
      color="primary"
      depressed
      :to="{ name: 'Awards' }"
    >
      <v-icon small class="mr-2">$prev</v-icon>
      {{ $t("awards.booking.goBackBtn") }}
    </v-btn>

    <BookingWishlistList v-if="listId" :listId="listId" />
  </div>
</template>
<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import BookingWishlistList from "@/components/awards/BookingWishlistList.vue";

import categoryMixins from "~/mixins/category";

import { mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "Booking",
  components: {
    CategoryTitle,
    BookingWishlistList
  },
  mixins: [categoryMixins],
  props: { storeId: { type: Number, required: false } },
  data() {
    return {
      listId: null
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {},
  mounted() {
    this.listId = get(this.$route, "params.listId");
  }
};
</script>
