<template>
  <div class="booking-list-detail-container mt-7" v-if="list">
    <div v-if="list.wishlistItems.length > 0">
      <v-row
        no-gutters
        class="font-weight-bold mb-3"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-col cols="4" lg="4">
          {{ $t("awards.lists.header.product") }}
        </v-col>
        <v-col cols="3" class="hidden-sm-only text-center ">
          {{ $t("awards.lists.header.qty") }}
        </v-col>
        <v-col cols="2" class="hidden-sm-only text-center ">
          {{ $t("awards.lists.header.points") }}
        </v-col>
        <v-col cols="2" class="hidden-sm-only text-center">
          {{ $t("awards.lists.header.contribution") }}
        </v-col>
      </v-row>

      <v-list class="t-detail-list" v-if="list">
        <BookingWishlistListItem
          v-for="item in list.wishlistItems"
          v-bind:key="item.itemId"
          :item="item"
          :isActive="true"
          @removeItem="removeFromList"
          @update="updateList"
        />
      </v-list>
    </div>
    <v-card
      light
      depresses
      elevation="0"
      v-if="list.wishlistItems.length + list.wishlistInactiveItems.length == 0"
      class="px-3"
    >
      <v-card-title class="px-0 py-4 font-weight-bold">{{
        $t("awards.lists.noProducts")
      }}</v-card-title>
    </v-card>

    <v-card class="book-card green lighten-2 mt-5">
      <v-card-text>
        <!-- addressID : 160 -->
        <v-checkbox
          dense
          color="primary"
          hide-details
          class="delivery-choose"
          :label="$t('awards.lists.options.nextDrive')"
          v-model="checkbox1"
          @change="resetStoreSelected(1)"
        >
        </v-checkbox>
        <!-- addressID : 159 -->
        <v-checkbox
          dense
          color="primary"
          hide-details
          class="delivery-choose"
          :label="$t('awards.lists.options.nextHome')"
          v-model="checkbox2"
          @change="resetStoreSelected(2)"
        >
        </v-checkbox>

        <span class="d-block mt-5 mb-2">
          <v-icon class="mr-2">$info</v-icon>
          {{ $t("awards.lists.options.info") }}
        </span>
        <v-select
          v-if="listStore && listStore.length > 0"
          :disabled="checkbox1 || checkbox2"
          v-model="storeSelected"
          outlined
          dense
          :items="listStore"
          :label="$t('awards.lists.options.warehouseSelect')"
          return-object
        >
          <!-- item-value="addressId" -->
          <template v-slot:item="{ item }">
            <span>
              {{ item.addressName }}: {{ item.address1 }},
              {{ item.addressNumber }} {{ item.city }}
            </span>
          </template>
          <template v-slot:selection="{ item }">
            <span>
              {{ item.addressName }}: {{ item.address1 }},
              {{ item.addressNumber }} {{ item.city }}
            </span>
          </template>
        </v-select>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          class="mt-3 mt-md-0"
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          large
          @click="bookWishlist"
          :disabled="!checkbox1 && !checkbox2 && !storeSelected"
          min-width="250"
        >
          {{ $t("awards.lists.options.bookBtn") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.booking-list-detail-container {
  .v-expansion-panel {
    border-radius: 0;
    .v-expansion-panel-header {
      min-height: 20px;
      padding: 10px 24px;
    }
    .v-expansion-panel-content .v-expansion-panel-content__wrap {
      padding: 0px !important;
      .v-list-item {
        padding: 0;
        @media #{map-get($display-breakpoints, 'sm-and-down')} {
          border-bottom: 1px solid $gray-border-color;
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
<script>
import BookingWishlistListItem from "@/components/awards/BookingWishlistListItem.vue";

import { mapActions } from "vuex";

import deliveryReactive from "~/mixins/deliveryReactive";

import ListService from "~/service/listService";
import storeService from "@/commons/service/storeService";

export default {
  name: "BookingWishlistList",
  props: {
    listId: { type: Number, required: true },
    storeId: { type: Number, default: 6 }
  },
  data() {
    return {
      list: null,
      panel: [],
      storeSelected: null,
      listStore: [],
      checkbox1: false,
      checkbox2: false
    };
  },
  components: {
    BookingWishlistListItem
  },
  mixins: [deliveryReactive],
  computed: {},
  methods: {
    ...mapActions({
      addProductsFromWishlist: "cart/addProductsFromWishlist"
    }),
    async fetchListStore() {
      this.listStore = await storeService.getListStore(this.storeId);
    },
    resetStoreSelected(checkboxId) {
      if (checkboxId == 1) {
        this.checkbox2 = false;
      } else if (checkboxId == 2) {
        this.checkbox1 = false;
      }
      this.storeSelected = null;
    },
    async bookWishlist() {
      let storeData = null;
      if (this.checkbox1) {
        storeData = {
          addressId: 160
        };
      } else if (this.checkbox2) {
        storeData = {
          addressId: 159
        };
      } else if (this.storeSelected) {
        storeData = this.storeSelected;
      }

      if (storeData && storeData.addressId) {
        let res = await ListService.confirm(this.listId, storeData.addressId);
        if (res) {
          this.$router.push({
            name: "BookingCompleted",
            params: { store: storeData }
          });
        }
      }
    },
    async fetchList() {
      this.list = await ListService.getList(this.listId);
    },
    async removeFromList({ itemId, product }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeItemFromList", {
          productName: product.name,
          productShortDescr: product.shortDescr
        })
      });
      if (res) {
        const newList = await ListService.removeItemFromList(
          this.list,
          itemId,
          product.productId
        );
        this.list = newList;
      }
    },
    async emptyList() {
      const textConfirm = `Sei sicuro di voler svuotare la lista ${this.list.name}?`;

      let res = await this.$dialog.confirm({
        text: textConfirm
      });
      if (res) {
        const res = await ListService.emptyList(this.list);

        this.list = res;
      }
    },
    async addAllToCart() {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllToList")
      });
      if (res) {
        await this.addProductsFromWishlist(this.listId);
      }
    },
    async updateList({ newQuantity, item }) {
      const res = await ListService.updateProductsInList(
        this.listId,
        item,
        newQuantity
      );
      this.list = res;
    },
    reload() {
      this.fetchList();
    }
  },
  created() {
    this.reload();
    this.fetchListStore();
  },
  watch: {
    listId() {
      this.reload();
    }
  }
};
</script>
