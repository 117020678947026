import { render, staticRenderFns } from "./BookingWishlistList.vue?vue&type=template&id=33cf396e&"
import script from "./BookingWishlistList.vue?vue&type=script&lang=js&"
export * from "./BookingWishlistList.vue?vue&type=script&lang=js&"
import style0 from "./BookingWishlistList.vue?vue&type=style&index=0&id=33cf396e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports